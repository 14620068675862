import React from "react";
import '../stylesheets/home.scss'
import bandPic from '../img/new band pic2.png'
import logo from '../img/BSOT_new_logo.png'
// import logo from '../img/logo_small.png'

const Home = () => {

    React.useEffect(() => {
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:home/up')
            .then(response => response.json())
            .then(data => {
                console.log(data)


            })
    }, []);

    return <span>
        <div className="home">
            <div>

                <span>
                    <img src={bandPic} alt='Band Photo' />
                </span>

                <span className="logo">
                    <img src={logo} />
                </span>

                <span>
                    <p>
                        Melbourne based progressive metal. Formed in January of 2013 by Samuel Black Sea of Trees are a 4 piece band taking influence from other prog bands like Opeth, Katatonia, Tool and Karnivool.
                    </p>
                </span>

                <span>
                    <ul className="news" >
                        <li>
                            On 19th of August 2020, a cover of ‘Hours of Wealth’ by one of the most influential metal bands in the world, Opeth, is released!
                        </li>
                        <li>
                            First single after the first album 'Through Sky' has gone live on all the streaming platforms and available for download on Bandcamp!! Check it out!!
                        </li>
                        <li>
                            Music video for 'Spiritual Beast' is available on Youtube
                        </li>
                        <li>
                            On 25 March full album 'Spiritual Beast' was released on all the streaming platforms and available for download on Bandcamp!! Check it out!!
                        </li>
                    </ul>
                </span>

            </div>
        </div>
        { }

    </span>
}


export default Home