import React from "react";
import GigRow from './GigRow3'
import '../stylesheets/GigList.scss'
import proginthehills2 from "../img/proginthehills2.jpg"
import approgximate1 from "../img/approgximate1.jpg"
import bar303gig from "../img/bar303gig.jpeg"
import bendigogig from "../img/Red-orchid-sessions-gig.jpg"
import eastballaratgig from "../img/eastBallaratGig.jpeg"
import workersclubgig from "../img/355678931_649881907014029_485565836281153361_n.jpg"
import oldbargig from "../img/oldBarGig.jpeg"
import dec9gig from "../img/dec9gig.jpeg"
import dec16gig from "../img/dec16gig.jpeg"

const gigsOld = [
    {
        "venue": "The Tote Upstairs",
        "date": "16-12-2023",
        "detail": "https://tickets.oztix.com.au/outlet/event/ee9a9fc8-caf2-4203-a11f-da879b6276a5",
        "img": dec16gig
    },
    {
        "venue": "Whole Lotta Love Bar",
        "date": "9-12-2023",
        "detail": "https://www.eventbrite.com.au/e/benthams-head-debut-single-launch-tickets-746650360327",
        "img": dec9gig
    },
    {
        "venue": "The Workers Club",
        "date": "28-Jul-2023",
        "detail": "https://www.facebook.com/events/166348319635293",
        "img": workersclubgig
    },
    {
        "venue": "The Eastern Ballarat",
        "date": "27-may-2023",
        "detail": "https://theeasternballarat.com.au/show/the-sinsemillian-metamorphosis-album-launch/",
        "img": eastballaratgig
    },
    {
        "venue": "The Old Bar",
        "date": "22-may-2023",
        "detail": "https://www.theoldbar.com.au/gigs/kaiju-presents-morbid-mondays-w-sundreamer-free-entry",
        "img": oldbargig
    },
    {
        "venue": "Bendigo Hotel",
        "date": "18-nov-2022",
        "detail": "www.eventbrite.com/e/fumarole-with-levitating-churches-tickets-449827274487",
        "img": bendigogig
    },
    {
        "venue": "303 bar",
        "date": "10-apr-2021",
        "detail": "https://www.trybooking.com/BPPWB",
        "img": bar303gig
    },
    {
        "venue": "The Leadbeater Hotel",
        "date": "8-feb-2020",
        "detail": "Approgximate #1 at The Leadbeater Hotel",
        "img": approgximate1
    },
    {
        "venue": "Belgrave Sooki Lounge",
        "date": "3-jan-2020",
        "detail": "Prog in the hills #2",
        "img": proginthehills2
    },
]


const GigList = () => {
    const [gigs, setGigs] = React.useState(0)

    React.useEffect(() => {

        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:gigs/up')
            .then(response => response.json())
            .then(data => console.log(data))


        fetch('https://dl.dropboxusercontent.com/scl/fi/ehkqgjxdpryf0y8x4vjma/gigs.json?rlkey=4v9j1z10fqghokdmro9hy8eze&dl=0&raw=1')
            .then(response => response.json())
            .then(data => {
                let tempGigs = []

                for (let gig of data) {
                    gig.img = gig.img.replace("www.dropbox", "dl.dropboxusercontent").concat("&raw=1")
                    const dateSplit = gig.date.split("-")
                    gig.date = new Date(dateSplit[1] + '-' + dateSplit[0] + '-' + dateSplit[2])
                    tempGigs.push(gig)
                }
                setGigs(tempGigs)

            })
    }, []);

    if (!gigs) {
        return <div> Loading...</div>
    } else {
        return (
            <div className="gig-list">
                <p> test</p>
                <table>
                    {/* <thead>
                        <tr>
                            <th>Date</th>
                            <th>Venue</th>
                            <th>Banner</th>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                            </td>
                        </tr>
                    </thead> */}
                    <tbody>
                        {
                            gigs.map((day, i) =>
                                <GigRow key={i} {...day} />
                            )
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default GigList