import React from "react";
import SpotifyPlayer from 'react-spotify-player'
import '../stylesheets/spotify.scss'

const Spotify = () => {
  // console.log()
    const size = {
        width: '70%',
        height: window.innerHeight * 0.7
      };
      const size2 = {
        width: '70%',
        height: 170,
      };

    //   const view = 'list'; // or 'coverart'
      const view = 'coverart'; // or 'coverart'
      const theme = 'black'; // or 'white'

    return (
        <div className="home">

        <SpotifyPlayer
            //uri="spotify:album:1TIUsv8qmYLpBEhvmBmyBk"
            uri="https://open.spotify.com/artist/3zogtBHiOCIaGsOfy7y6oS"
            size={size}
            view={view}
            theme={theme}
        />

        </div>
    )
}

export default Spotify