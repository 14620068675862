import React from "react";
import '../stylesheets/Media.scss'
import YoutubePlayer from './Youtube'
import FacebookVideo from './FacebookVideo'


function importAll(r) {
    console.log(r)
    return r.keys().map(r);
}

const videos = [
    {
        link: '0aFYEI9q610',
        text: "The Spiritual Beast | Black Sea of Trees Single from the forthcoming album 'The Spiritual Beast'"
    },
    {
        link: 'Nfi751ZSCVg',
        text: "Over the Dunes | Black Sea of Trees Single from 'The Spiritual Beast' 2023 album'"
    },
    {
        link: 'SiWlVRa0Lis',
        text: "Acoustic rendition of Black Sea of Tree's 'Through Sky'"
    },
    {
        link: 'Uh1yLjPBsGA',
        text: "Black sea of trees - Through Sky @Sooki lounge Belgrave"
    },
    {
        link: 'xDEiyQCSU-c',
        text: "Black Sea of Trees - Precipice @ Leadbeater hotel"
    }
]


const Media = () => {

    React.useEffect(() => {
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:videos/up')
            .then(response => response.json())
            .then(data => {
                console.log(data)
            })
    }, []);

    return (

        <div>
            <h1>Videos</h1>
            {/* <div className="facebook">
                <FacebookVideo videoId='794896221317283' appId=''/>
            </div>*/}
            <div className="flex-container">
                {
                    videos.map((video, i) =>
                        <>

                            {/* <br></br> */}
                            <span>

                                <p>
                                    <div className="youtube">
                                        <YoutubePlayer videoId={video.link} />
                                    </div>
                                    {video.text}
                                </p>
                            </span>
                        </>

                    )
                }
            </div>
           

        </div>
    )
}

export default Media