import React from 'react';
import ReactDOM from 'react-dom';
import FacebookPlayer from 'react-facebook-player';
 
class FacebookVideo extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      player: null,
    };
  }
 
  onReady = (player) => {
    this.setState({
      player: player,
    });
  }
 
  playVideo = () => {
    const { player } = this.state;
    if (player) player.play();
  }
 
  pauseVideo = () => {
    const { player } = this.state;
    if (player) player.pause();
  }
 
  render() {
    const { videoId, appId } = this.props;
    return (
      <div>
        <FacebookPlayer
          videoId={ videoId }
          appId={ appId }
          onReady={ this.onReady }
          />
        <button onClick={ this.playVideo }>Play</button>
        <button onClick={ this.pauseVideo }>Pause</button>
      </div>
    );
  }
}
export default FacebookVideo;
 
// ReactDOM.render(<FacebookVideo />, document.getElementById('root'));