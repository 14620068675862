import React from "react";
import '../stylesheets/Media.scss'
import YoutubePlayer from './Youtube'
import FacebookVideo from './FacebookVideo'


function importAll(r) {
    console.log(r)
    return r.keys().map(r);
}

const photos = [
    '026A3105-Enhanced-NR.jpg',
    '026A2975-Enhanced-NR.jpg',
    '026A3009-Enhanced-NR.jpg',
    '026A3186-Enhanced-NR.jpg',
    '328719923_868151497609572_5126458747415507483_n.jpg',
    '329954414_911100670035785_8939103574399151583_n.jpg',
    '330409666_1269230060339267_146971984300359050_n.jpg',
    '336360047_3431192513822796_3871479397373473431_n.jpg',
    '337007380_893077388587517_1823551887726244612_n.jpg',
    '337016659_1474753536387791_5311755510147643101_n.jpg',
    '337027649_522180350106082_1022115114282547019_n.jpg',
    '337047403_758676115573670_6804483008502601325_n.jpg',
    '337522941_780201990189330_8448673046424506445_n.jpg',
    '337864110_580468464041396_6416659579454098932_n.jpg',
    '338000627_3274856672826349_393537862582997277_n.jpg',
    '338319871_891702495447734_2605759972672276017_n.jpg',
    '330316504_5897699013619253_470712027343584058_n.jpg',
    '686943897335608596.jpeg',
    'fb1.jpg',
    'fb2.jpg',
    'fb3.jpg',
    'fb4.jpg',
]


const Media = () => {
    React.useEffect(() => {
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:media/up')
            .then(response => response.json())
            .then(data => {
                console.log(data)
            })
    }, []);

    return (

        <div>
            <h1>Photos</h1>
            <div className="flex-container">
                {/* <div> <img src={process.env.PUBLIC_URL + '/photos/fb1.jpg'} /> </div> */}
                {/* <div> <img src={fb2} /> </div>
                <div> <img src={fb3} /> </div>
                <div> <img src={fb4} /> </div> */}
                {
                    photos.map((photo) =>
                        <div> <img src={process.env.PUBLIC_URL + '/photos/' + photo} /> </div>

                    )
                }


                {/* 
            <div>
                <img src='https://scontent.fmel7-1.fna.fbcdn.net/v/t31.0-8/p720x720/10296443_715245095180291_3184503212465085075_o.jpg?_nc_cat=105&_nc_sid=e007fa&_nc_ohc=ypUefrNj3hoAX-uE4wF&_nc_ht=scontent.fmel7-1.fna&_nc_tp=6&oh=27334e6cd5381517eeacc97f2227a80c&oe=5F5E68D4'/>
            </div>


            <div>
                <img src={'https://scontent.fmel7-1.fna.fbcdn.net/v/t1.0-9/s1080x2048/81165880_2754085847962862_5502813022776721408_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_ohc=6ILFOOnbqKIAX_Re59G&_nc_ht=scontent.fmel7-1.fna&_nc_tp=7&oh=793ecf316d101559ea52bfb7d66073ac&oe=5F5D4329'} />
            </div>


            <div>
                <img src={'https://scontent.fmel7-1.fna.fbcdn.net/v/t1.0-9/p720x720/110189166_3228060440565398_7595948514436234809_o.jpg?_nc_cat=104&_nc_sid=8024bb&_nc_ohc=qzT1s4whQVQAX923JVm&_nc_ht=scontent.fmel7-1.fna&_nc_tp=6&oh=526c899924f87a19a9612399450b6142&oe=5F5DBBF0'} />
            </div> */}
                {/* 
                <div>

                    <img src={jan} />
                </div> */}

                {/* <div>
                <img src={

                    'https://scontent.fmel7-1.fna.fbcdn.net/v/t31.0-8/p720x720/1102630_582350445136424_1902780472_o.jpg?_nc_cat=110&_nc_sid=e007fa&_nc_ohc=kpQwbRjYK8AAX-JGIH1&_nc_ht=scontent.fmel7-1.fna&_nc_tp=6&oh=61a9381a3b0aea73df39d6630db2fa2c&oe=5F5F2DF4'
                } />
            </div> */}
            </div>

        </div>
    )
}

export default Media