import React from "react";
import Home from './ui/Home'
import GigList from './ui/GigList3'
import About from './ui/Info'
import Footer from './ui/Footer'
import Discography from './ui/Dicography'
import Media from './ui/Media'
import VideoLibrary from './ui/Videos'
import Stats from './ui/Stats'
import Spotify from './ui/Spotify'
import Redirect from './Redirect'
import HomeIcon from 'react-icons/lib/fa/home'
import GigsIcon from 'react-icons/lib/fa/table'
import { Icon } from '@iconify/react';
import infoIcon from '@iconify/icons-ion/information-circle-outline';
import CDIcon from '@iconify/icons-uil/compact-disc';
import CameraIcon from '@iconify/icons-ic/baseline-photo-camera';
import VideoLibraryIcon from '@iconify/icons-ic/baseline-video-library';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './stylesheets/Menu.scss'

export default function AppRouter() {
  return (
    <Router>
      <div className="app">
        <nav className="menu">
          <Link to="/"><HomeIcon /></Link>
          <Link to="/giglist"><GigsIcon /></Link>
          <Link to="/about"><Icon icon={infoIcon} className="movedown" /></Link>
          <Link to="/discography"><Icon icon={CDIcon} className="movedown" /></Link>
          <Link to="/media"><Icon icon={CameraIcon} className="movedown" /></Link>
          <Link to="/videoLibrary"><Icon icon={VideoLibraryIcon} className="movedown" /></Link>
        </nav>
        <hr />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/giglist">
            <GigList />
          </Route>
          <Route path="/discography" component={Discography} />
          <Route path="/media" component={Media} />
          <Route path="/videoLibrary" component={VideoLibrary} />
          <Route path="/stats" component={Stats} />
          <Route
            path="/spotify1"
            component={Redirect}
            loc="https://open.spotify.com/artist/3zogtBHiOCIaGsOfy7y6oS"
          />
          <Route path="/spotify" component={Spotify} />
          <Route path="/apple" >
            <Redirect loc={"https://music.apple.com/au/artist/black-sea-of-trees/946433249"} />
          </Route>
          <Route path="/facebook">
            <Redirect loc={"https://www.facebook.com/blackseaoftrees/"} />
          </Route>
          <Route path="/bandcamp">
            <Redirect loc="https://blackseaoftreesofficial.bandcamp.com/" />
          </Route>
          <Route path="/soundcloud">
            <Redirect loc="https://soundcloud.com/blackseaoftreesofficial/tracks" />
          </Route>
          <Route path="/lastfm">
            <Redirect
              loc="http://www.last.fm/music/Black+Sea+of+Trees"
            />
          </Route>
          <Route
            path="/instagram">
            <Redirect
              loc="https://www.instagram.com/blackseaoftrees"
            />
          </Route>
          {/* <Route path="*" component={Whoops404} /> */}
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}