import React from "react";
import '../stylesheets/info.scss'
import sam from '../img/sam.jpg'
import tanil from '../img/tanil.jpg'
import jan from '../img/jan2.jpg'
import francesco from '../img/francesco.jpg'
import aboutUs from '../img/about us.png'
import bandMembersAre from '../img/bandmembers are.png'
const Info = () => {
    React.useEffect(() => {
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:info/up')
            .then(response => response.json())
            .then(data => {
                console.log(data)
            })
    }, []);

    return (
        <div className="info">
            <div >
                <span>
                <span >
                    <img src={aboutUs} className="header" />
                </span>
                </span>
                <span>
                    <p>
                    {/* Melbourne based Progressive Metal. Formed in January of 2013 Black Sea of Trees released their debut album ‘The Departer’ in late 2014. In 2016 the band went on hiatus in which 4/5 members left. The band was reformed by Nov 2018 with a sophomore album is in the works planned to be finished sometime in 2021. */}
                    Melbourne based Dark Progressive Metal, Black Sea of Trees was founded in 2013 by Sam. Starting out as a five piece band they quickly created their first album ‘The Departer’ which released in late 2014. After playing a few gigs around Melbourne the vocalist and drummer left to pursue another project (Little Rituals) which prompted a hiatus in 2016 where all remaining members quit except Sam. By November 2018 a new lineup had formed, Sam taking over vocals & guitar, Francesco - bass, whilst still an active member in Neptune, Jan - drums, after the dissolving of The National Evening Express and Tanil - lead guitar, creator of his own solo project and a previous member of many bands including SST. This completed a lineup consisting of different nationalities in pure Melbournian fashion, all bringing different and multi-faceted influences and backgrounds to the band. In 2020 the band began playing shows and working on a new album which they aim to release in 2021.
                        </p>
                </span>
                <span>
                    {/* <h2>
                        Band members are:<br></br>
                        <br></br>
                    </h2> */}
                    <span >
                    <img src={bandMembersAre} className="header" />
                </span>
                </span>
                <span className="photos">
                    <div>
                        <img src={sam} />
                        <p>
                            Samuel Exton - lead vocals & guitars <br></br>
                        </p>

                    </div>
                    <div>
                        <img src={tanil} />
                        <p>
                            Tanil Cagdas - lead guitars & backing vocals <br></br>
                        </p>
                    </div>
                    <div>
                        <img src={francesco} />
                        <p>

                            Francesco Adami - bass guitars <br></br>
                        </p>
                    </div>
                    <div>
                        <img src={jan} />
                        <p>

                            Jan Schotting - drums <br></br>
                        </p>
                    </div>

                </span>
            </div>
        </div>
    )
}

export default Info