import React from "react";
import '../stylesheets/Discography.scss'
import througsky from '../img/Through sky.jpg'
import hoursofwealth from '../img/Hours of Wealth.jpg'
//  import departer from '../img/BSOT.jpg'
import spiritualBeast from '../img/The_Spiritual_Beast.jpeg'

const Discography = () => {
    React.useEffect(() => {
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:discography/up')
            .then(response => response.json())
            .then(data => {
                console.log(data)
            })
    }, []);
    return (


        <div className="flex-container1">

            <div>
                <img src={spiritualBeast} />
                <p>
                    Full album 'The Spiritual Beast' was released in 2023.< br></br>
                    <a href="https://open.spotify.com/album/2Cr2rXnHSaSj59nSYcPBA5?si=7baa7c48a4a14dd5">Listen in Spotify</a>< br></br>
                    <a href="https://music.apple.com/us/album/the-spiritual-beast/1671127109">Listen in Apple Music</a>< br></br>
                    <a href="https://blackseaoftreesofficial.bandcamp.com/album/the-spiritual-beast">Listen in Band Camp</a>< br></br>
                </p>
            </div>


            <div>
                <img src={througsky} />
                <p>
                    Through sky single is released on 10 August 2020.< br></br>
                    <a href="https://open.spotify.com/album/3qoU74iCu7T4KHuEO2IZeO">Listen in Spotify</a>< br></br>
                    <a href="https://music.apple.com/au/album/through-sky-single/1526043861">Listen in Apple Music</a>< br></br>
                    <a href="https://blackseaoftreesofficial.bandcamp.com/track/through-sky">Listen in Band Camp</a>< br></br>
                </p>
            </div>

            <div>
                <img src={hoursofwealth} />
                <p>
                    Opeth cover Hours of Wealth is released on 10 August 2020.< br></br>
                    <a href="https://open.spotify.com/album/4PcLXbt0fSC1VcIgtCbOFe">Listen in Spotify</a>< br></br>
                    <a href="https://music.apple.com/au/album/hours-of-wealth-single/1527969416">Listen in Apple Music</a>< br></br>
                    <a href="https://blackseaoftreesofficial.bandcamp.com/track/hours-of-wealth-cover">Listen in Band Camp</a>< br></br>
                </p>
            </div>

            {/* <div>
                <img src={departer} />
                <p>
                    Debut album 'The Departer' was released in 2014.< br></br>
                    <a href="https://open.spotify.com/album/7xZpioXSCxU3m0FYcoR6ek">Listen in Spotify</a>< br></br>
                    <a href="https://music.apple.com/au/album/the-departer/1524142586">Listen in Apple Music</a>< br></br>
                    <a href="https://blackseaoftreesofficial.bandcamp.com/album/the-departer">Listen in Band Camp</a>< br></br>
                </p>
            </div> */}


        </div>
    )
}

export default Discography