import React from "react";
const GigRow = ({ venue, date, detail, img}) =>
    <>
    <tr>
        <td>
        <img src={img}></img>
            
            </td>
    </tr>
     <tr>
     <td>
        {/* </td>
        <td> */}
         {date.toDateString()}
         </td>
    </tr>
     <tr>
     <td>
            
            <a href={detail}>{venue}</a>< br></br>
        {/* </td>
        <td> */}
       
           
        </td>
    </tr>
    </>

export default GigRow