import React from "react";
import '../stylesheets/home.scss'





const Stats = () => {
    const [gigs, setGigs] = React.useState(0)
    const [home, setHome] = React.useState(0)
    const [info, setInfo] = React.useState(0)
    const [discography, setDiscography] = React.useState(0)
    const [media, setMedia] = React.useState(0)
    const [videos, setVideos] = React.useState(0)

    React.useEffect(() => {


        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:home/')
            .then(response => response.json())
            .then(data => setHome(data.count))
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:info/')
            .then(response => response.json())
            .then(data => setInfo(data.count))
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:discography/')
            .then(response => response.json())
            .then(data => setDiscography(data.count))
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:media/')
            .then(response => response.json())
            .then(data => setMedia(data.count))
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:videos/')
            .then(response => response.json())
            .then(data => setVideos(data.count))
        fetch('https://api.counterapi.dev/v1/:blackseaoftrees.com.au/:gigs/')
            .then(response => response.json())
            .then(data => setGigs(data.count))

    }, []);


    return (
        <div className="stats">
            <div>
                <span>
                    home {home}
                </span>
                <span>
                    gigs {gigs}
                </span>
                <span>
                    info {info}
                </span>
                <span>
                    discography {discography}
                </span>
                <span>
                    media {media}
                </span>
                <span>
                    videos {videos}
                </span>
            </div>
        </div>
    )

}

export default Stats