import React from 'react';
import YouTube from 'react-youtube';
 
class YoutubePlayer extends React.Component {

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
        }

  render() {
    const opts = {
      // height: '390',
      // width: '640',
      height: '800',
      width: '2000',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        enablejsapi: 1,
        rel: 0,
      },
    };
 
    //"_nBlN9yp9R8"
    const {videoId}=this.props
    return <YouTube videoId={videoId} opts={opts} onReady={this._onReady} />;
  }
 

}
export default YoutubePlayer