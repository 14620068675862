import React from 'react'
import { Link } from 'react-router-dom'
import '../stylesheets/Footer.scss'
import { Icon, InlineIcon } from '@iconify/react';
import spotifyIcon from '@iconify/icons-mdi/spotify';
import applemusicIcon from '@iconify/icons-simple-icons/applemusic';
import bxlFacebookCircle from '@iconify/icons-bx/bxl-facebook-circle';
import lastfmIcon from '@iconify/icons-mdi/lastfm';
import soundcloudIcon from '@iconify/icons-mdi/soundcloud';
import bandcampIcon from '@iconify/icons-simple-icons/bandcamp';
import instagramAlt from '@iconify/icons-uil/instagram-alt';




const Footer = () => {



    return (
        <div className="footer">

            <div>
                <Link to="/spotify" >
                    <Icon icon={spotifyIcon} />
                </Link>
            </div>
            <div>
                <Link to="/apple" >
                    <Icon icon={applemusicIcon} />
                </Link>
            </div>
            <div>
                <Link to="/facebook" >
                    <Icon icon={bxlFacebookCircle} />
                </Link>
            </div>
            <div>
                <Link to="/instagram" >
                    <Icon icon={instagramAlt} />
                </Link>
            </div>
            <div>
                <Link to="/lastfm" >
                    <Icon icon={lastfmIcon} />
                </Link>
            </div>
            <div>
                <Link to="/soundcloud" >
                    <Icon icon={soundcloudIcon} />
                </Link>
            </div>
            <div>
                <Link to="/bandcamp" >
                    <Icon icon={bandcampIcon} />
                </Link>
            </div>
            <div className="copyright">
                
                copyright © 2020 Tanil Cagdas
                
            </div>

        </div>
    )

}



export default Footer